import { GatsbySSR } from 'gatsby';
import React from 'react';
import Layout from './src/components/layout';
import "./src/global.css";

type WrapPageElement = GatsbySSR['wrapPageElement'];
type WrapRootElement = GatsbySSR['wrapRootElement'];

export const wrapPageElement: WrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const wrapRootElement: WrapRootElement = ({ element }) => (
  <>{element}</>
);