import styled from "styled-components";

export type signal = {
  _id: string;
  baseCurrency: string;
  coindex: number;
  date: string;
  description: string;
  emaSlope: number;
  futuresType: number;
  groupType: number;
  priceGap: number;
  resultValue: number;
  shiftSeconds: number;
  signalWeight: number;
  timeframe: number;
  timestamp: number;
  type: string;
  value: number;
  targetPrice: number;
  closePrice: number;
  winRate: number;
  goldenRatio: number;
  count: number;
  won: number;
  lost: number;
};

export type TodayProps = {
  serverData: {
    data: {
      signals: signal[];
      report?: signal;
      reportTypes?: signal[];
    };
  };
};

export const NavBar = styled.div(
  () => `
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
`
);

export const Grid = styled.div(
  () => `
  justify-content: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
);

export const Main = styled.body(
  () => `
  background: #383838;
  color: #00dd00;
  font-size: 64.5%;
  font-size: 1.6rem;
  font-family: 'VT323', Monospace;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: column;
`
);

export const Center = styled.div(
  () => `
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
`
);
